<template>
	<div>
		<s-crud
			title="Grupos de Producción"
            ref="gridgroupperson"
			:config="config"
			:filter="filter"            
			add			
			edit
            add-no-function     	
			search-input
            @rowSelected="rowSelected($event)"
            @add="add()"
            @edit="edit()"
            searchInput
		>       
			<template v-slot:filter>
                <v-container>
                    <v-row justify="center" >
                        <v-col cols="12" lg="2" md="4" sm="12" class="pt-0">
                            <s-select-definition
                                label="Turno"
                                :def="1278"
                                v-model="filter.TypeTurn"
                                clearable
                            ></s-select-definition>
                        </v-col>
                    </v-row>
                </v-container>
            </template>
            
            <template v-slot:PdgStatus="{ row }">
				<v-chip x-small=""  :color="row.PdgStatus == 1 ? 'success': 'error'" >
					{{ row.PdgStatus == 1 ? 'Activo': 'Inactivo' }}
				</v-chip>
        	</template>

            <template v-slot:numberPerson="{ row }">
				<v-chip x-small=""  :color="row.numberPerson > 0 ? 'warning': 'error'" >
					{{ row.numberPerson }}
				</v-chip>
        	</template>           
		</s-crud> 
        <v-dialog max-width="auto" v-model="openDialog" persistent>
            <group-detail @save="save()" @close="close()" :selectGroup="selectGroup" v-model="PgdID"></group-detail>
        </v-dialog>
	</div>
</template>



<script>
	import _sProductionGroupService from "@/services/FrozenProduction/FrzProductionGroupService.js";
    import GroupDetail from "./FrzProductionGroupDetail.vue"
    

	export default {
        components: { GroupDetail },
		data: () =>({

            config: {
                model: {
                    PdgID: "ID",
                    PrsDocumentNumber: "string",
                    PdgStatus:"",
                    numberPerson:""
                },
                service: _sProductionGroupService,
                headers: [
                    {text: "ID", value: "PdgID"},
                    {text: "Nombre Grupo", value: "PdgNameGroup"},
                    {text: "Turno", value: "TypeTurnName"},
                    {text: "Estado", value: "PdgStatus"},
                    {text: "N° Integrantes", value: "numberPerson"}
                ]
            },
            filter: { TypeTurn:0 },
            
            openDialog: false,
            PgdID: 0,
            selectGroup: [],
		}),
 
		methods: {

            add()
            {              
                this.PgdID = 0;
                this.selectGroup = [];
                this.openDialog = true;
            },
            edit(){                             
                this.openDialog = true;
            },
            close(){                
                this.openDialog = false;
                this.PgdID = '';
                this.selectGroup = [];
                this.$refs.gridgroupperson.refresh();
            },
            save(){
                this.$refs.gridgroupperson.refresh();
            },
            
            rowSelected(item){
                this.selectGroup = item;
                this.PgdID = this.selectGroup.length > 0 ? this.selectGroup[0].PdgID : 0;
            },
        },
            
	}
</script>